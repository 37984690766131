<template>
  <div class="home">
    <section class="sec sec1">
      <div>
        <h1>DISRUPTIVE TECHNOLOGY FUTURE INC</h1>
        <p>Guatemala</p>
      </div>
      <img src="../assets/IMG-20220521-WA0001.webp" alt="Futureinc Guatemala" />
    </section>

    <section class="sec sec4">
      <div class="animate release">
        <h1>NUESTRA HISTORIA</h1>
        <p>
          Somos un grupo joven y talentoso de emprendedores e ingenieros con una
          idea innovadora que esperamos contribuya a un mañana mejor. Ofrecemos
          soluciones inteligentes para empresas de todos los tamaños, estamos
          orgullosos de nuestro servicio dedicado a medida e incomparable en el
          Mercado Centro Americano.
        </p>
      </div>
      <img
        src="../assets/xconvert.com.webp"
        class="animate release"
        alt="Car chargers from FutureInc"
      />
    </section>
    <section class="sec sec4 sec5">
      <div class="animate release">
        <h1>NUESTRA TECNOLOGÍA INNOVADORA</h1>
        <p>
          En Future Inc., nuestras soluciones tecnológicas son integrales ayudan
          a las empresas e individuos ya posicionados como líderes en la
          industria a lograr un mayor éxito. Nuestra plataforma tiene una amplia
          gama de características que se crearon con el objetivo de ayudarlo a
          vivir de manera diferente. Con el fin de capacitar a nuestros usuarios
          para que lo hagan mejor, Future Inc. está continuamente construyendo
          sobre su tecnología, así que manténgase en contacto para obtener
          información sobre actualizaciones y otros cambios. Para una
          demostración gratuita, contáctenos hoy.
        </p>
        <router-link class="btn" to="./about"
          >Descubrir <span><i class="fa-solid fa-angles-right"></i></span
        ></router-link>
      </div>
      <img
        src="../assets/EV-chargers-in-a-carpark.webp"
        class="animate release"
        alt="Charging Stations from FutureInc"
      />
    </section>
    <section class="sec sec2">
      <img
        class="animate release"
        src="../assets/R.jpg"
        alt="Consultant from FutureInc"
      />
      <div class="animate release">
        <h1>Consultoria</h1>
        <p>Encuentre soluciones de IA para su empresa de energía</p>
        <router-link to="./schedule" class="btn"
          >Book Now <span><i class="fa-solid fa-angles-right"></i></span
        ></router-link>
      </div>
    </section>
    <section class="sec sec2 sec3">
      <div class="animate release">
        <h1>Adquiere un Tesla</h1>
        <p>Vehiculos certificados con garantia de 3 años en motor y bateria</p>
        <router-link to="./schedule" class="btn"
          >Book Now <span><i class="fa-solid fa-angles-right"></i></span
        ></router-link>
      </div>
      <img
        class="animate release"
        src="../assets/tesla-car.webp"
        alt="Tesla Cars from FutureInc"
      />
    </section>
    <section class="sec sec2">
      <img
        class="animate release"
        src="../assets/eldo-rafael-iLrMw5v4afE-unsplash.webp"
        alt="Franchisis from FutureInc"
      />
      <div class="animate release">
        <h1>Franquicias</h1>
        <p>Agrega tu marca en las estaciones de carga</p>
        <router-link to="./schedule" class="btn"
          >Book Now <span><i class="fa-solid fa-angles-right"></i></span
        ></router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    document.title = "FutureInc | Get EV chargers, tesla cars in one place";

    const sections = document.querySelectorAll(".animate");

    const options = {
      root: null,
      threshold: 0,
      rootMargin: "-40% 0%",
    };

    var observer = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        entry.target.classList.remove("release");
      });
    }, options);

    sections.forEach((section) => {
      observer.observe(section);
    });
  },
};
</script>

<style scoped>
.btn {
  text-decoration: none;
}
.animate {
  transition: all 1s ease-in-out;
}
.sec {
  position: relative;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  display: flex;
  padding: 0 min(10%, 2rem);
}
.sec1 {
  align-items: center;
  justify-content: center;
  color: var(--color-dark);
  flex-direction: column;
  min-height: 90vh;
  padding: 0;
}
.sec1 > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  z-index: -10;
  object-fit: cover;
  object-position: left;
  filter: brightness(0.5);
}

.sec1 div {
  text-align: center;
  font-size: 3rem;
  width: min(40ch, 100%);
  color: var(--color-light);
  animation: fade-in 2s ease;
}
.sec1 div p {
  font-size: 1em;
  font-family: smooch, sans-serif;
}

.sec2 {
  min-height: 60vh;
  justify-content: space-around;
  color: var(--color-dark);
}
.sec2 > img {
  width: 40%;
  object-fit: contain;
  align-self: center;
}
.sec2 > img.release {
  transform: translate(-100px);
  opacity: 0;
}

.sec2 > div {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  text-align: right;
}
.sec2 > div.release {
  transform: translate(100px);
  opacity: 0;
}
.sec2 > div::before {
  content: "";
  position: absolute;
  width: 130%;
  height: 100%;
  background: lightgray;
  transform: skew(-10deg) translateX(15%);
}
.sec2 div > h1 {
  font-size: 2rem;
  opacity: 0.9;
}
.sec2 div > p {
  opacity: 0.8;
  margin: 1rem 0 2rem;
  font-size: 1.1rem;
}
.sec2 > div > .btn {
  background: var(--color-btn-back);
  color: var(--color-light);
}

.sec3 > div {
  align-items: flex-start;
  text-align: left;
}
.sec3 > div.release {
  transform: translate(-100px);
  opacity: 0;
}
.sec3 > div::before {
  transform: translateX(-25%) skew(-10deg);
}

.sec3 > img {
  width: auto;
  height: 15rem;
}
.sec3 > img.release {
  transform: translate(100px);
  opacity: 0;
}
.sec4 {
  min-height: 60vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.sec4 > div {
  min-height: 100%;
  padding: 5em 7.5em;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
}

.sec4 > div > h1 {
  font-size: min(10vw, 3rem);
  opacity: 0.8;
  font-weight: bold;
  width: 100%;
  position: relative;
  background: lightgray;
  padding: 0.5em 1em 0.5em 0;
}
.sec4 > div > h1::before {
  content: "";
  position: absolute;
  top: 0;
  width: 200%;
  height: 100%;
  transform: translateX(-45%) skew(-10deg);
  z-index: -1;
  background: lightgray;
}

.sec4 > div > p {
  font-size: 1.1rem;
  text-align: justify;
  opacity: 0.8;
  margin: 3rem 0;
  max-width: 100ch;
}
.sec4 > div.release {
  opacity: 0;
  transform: translateY(10%);
}
.sec4 img {
  width: 40%;
}
.sec4 img.release {
  opacity: 0;
  transform: translateX(10%);
}
@media only screen and (max-width: 426px) {
  .sec {
    padding: min(10%, 2rem);
  }
  .sec1 {
    padding: 2rem;
    text-align: center;
    align-items: center;
  }
  .sec1 div {
    font-size: 1.5rem;
  }
  .sec2 {
    flex-direction: column;
    overflow: hidden;
  }
  .sec2 > img {
    width: 40rem;
    transform: translate(-10%, -0%) scale(1.5);
    height: 25rem;
  }
  .sec2 > div {
    width: 100%;
  }
  .sec2 > div::before {
    transform: scaley(1.5) skew(-10deg) translateX(20%);
  }
  .sec3 {
    flex-direction: column-reverse;
  }
  .sec3 > img {
    height: 15rem;
    margin: 0 0 5rem 0;
    transform: none;
  }
  .sec3 > div::before {
    transform: scale(1.5) translate(-30%) skew(10deg);
  }
  .sec4 {
    flex-direction: column;
  }
  .sec4 > div {
    width: 100%;
    padding: 0;
  }
  .sec4 img {
    width: 100%;
    padding: 1em 0;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
  }
}
@keyframes waving {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}
</style>
