<template>
  <div class="page">
    <header>
      <h1 class="logo">FUTURE <span>INC</span></h1>
      <div class="ham" @click="ToggleNav">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav>
        <router-link @click="ToggleNav" to="/">Home</router-link>
        <router-link @click="ToggleNav" to="/services">Programa una cita</router-link>
        <router-link @click="ToggleNav" :to="{ name: 'Services', hash: '#stock' }"
          >stock disponible</router-link
        >
        <router-link @click="ToggleNav" to="/about">About</router-link>
        <router-link @click="ToggleNav" to="/faq">FAQ</router-link>
        <router-link @click="ToggleNav" to="/news">Press Kit</router-link>
        <router-link @click="ToggleNav" class="btn" to="/contact"
          >Contactanos</router-link
        >
      </nav>
    </header>
    <div class="content">
      <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
    <footer>
      <h1 class="logo">
        <a href="/">FUTURE <span>INC</span></a>
      </h1>
      <div class="contact">
        <p>+1 (502) 4698-7994</p>
        <p>mguillen@teslagt.com</p>
      </div>
      <ul class="about">
        <li>About</li>
        <li><a href="/about">Company</a></li>
        <li><a href="/faq">FAQ</a></li>
      </ul>
      <ul class="connect">
        <li>Get in touch</li>
        <li><a href="/contact">Contact us</a></li>
      </ul>
      <ul class="smedia">
        <li>Follow us</li>
        <li>
          <a
            href="https://www.facebook.com/109979515087771/posts/109987775086945/?substory_index=2"
            ><i class="fa-brands fa-facebook-square"></i> Facebook</a
          >
        </li>
        <li>
          <a href="https://www.instagram.com/futureinc.energy/"
            ><i class="fa-brands fa-instagram-square"></i> Instagram</a
          >
        </li>
        <li>
          <a href="https://twitter.com/FutureincGua"
            ><i class="fa-brands fa-twitter-square"></i> Twitter</a
          >
        </li>
      </ul>
      <p class="copyright">© 2022 Future.inc</p>
    </footer>
  </div>
</template>

<script>
  export default {
    methods: {
      ToggleNav() {
        document.querySelector("nav").classList.toggle("active");
      },
      togglechat() {
        document.querySelector(".chatbot .chat").classList.toggle("visible");
      },
    },
  };
</script>

<style>
  :root {
    --color-light: rgb(254, 254, 255);
    --color-dark: rgba(0, 0, 0, 0.925);
    --color-btn-back: rgba(16, 13, 33, 0.868);
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
  }
  html {
    min-height: 100%;
    overflow-x: hidden;
  }
  .page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  header {
    height: 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: min(10%, 50px) max-content 1fr max-content min(10%, 50px);
    align-items: center;
    box-shadow: 5px 0px 10px rgba(3, 0, 11, 0.546);
    position: fixed;
    z-index: 1000;
    background: var(--color-light);
  }
  .logo {
    grid-column: 2 /3;
    font-size: 2rem;
    font-family: "Roboto", sans-serif;
  }
  .logo a {
    color: var(--color-dark);
    text-decoration: none;
    font-family: "Roboto", sans-serif;
  }
  .logo span {
    color: red;
    font-weight: 500;
    font-size: 0.7em;
  }
  .ham {
    height: 1.5em;
    aspect-ratio: 1;
    flex-direction: column;
    justify-content: space-around;
    display: none;
  }
  .ham > span {
    background: var(--color-dark);
    height: 0.2rem;
    border-radius: 0.1rem;
  }
  nav {
    justify-self: end;
    display: flex;
    align-items: center;
    grid-column: 3 /4;
  }
  nav > a {
    color: var(--color-dark);
    margin-inline-end: 2em;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.8;
    position: relative;
  }
  nav > a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.3em;
    width: 100%;
    height: 0.2em;
    background-color: var(--color-btn-back);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.2s ease;
  }
  nav > a:hover::after {
    transform: scaleX(1);
  }
  nav > a.router-link-active::after,
  nav > a.router-link-exact-active::after {
    transform: scaleX(1);
  }
  .btn {
    position: relative;
    padding: 0.75em 1.5em;
    background: var(--color-btn-back);
    color: var(--color-light);
    border-radius: 0.2em;
    overflow: hidden;
    margin: 0;
    opacity: 1;
    transition: filter 0.4s ease;
  }
  .btn:hover {
    filter: invert();
  }
  .content {
    padding-top: 5rem;
    overflow-x: hidden;
  }

  footer {
    color: var(--color-dark);
    display: grid;
    padding: 2rem 0;
    gap: 2rem;
    min-height: 10rem;
    background-color: var(--color-light);
    grid-template-columns: min(10%, 50px) 1.5fr 1fr 1fr 1fr min(10%, 50px);
    grid-template-rows: max-content auto max-content;
    margin-top: auto;
    box-shadow: -10px 0px 15px rgba(0, 0, 0, 0.372);
  }
  footer > .logo {
    grid-row: 1/2;
    color: var(--color-dark);
    opacity: 0.9;
  }
  footer > .contact {
    grid-column: 2/ 3;
    grid-row: 2/3;
    padding: 0 4rem 0 0;
    font-size: 0.9rem;
  }
  footer > .contact > p {
    margin: 0 0 1em;
    width: 20ch;
  }
  footer > .about {
    grid-row: 2/3;
    grid-column: 3/4;
  }
  footer > .connect {
    grid-column: 4/5;
    grid-row: 2/3;
  }
  footer > .smedia {
    grid-column: 5/6;
    grid-row: 2/3;
  }
  footer > ul > li {
    list-style: none;
    padding: 0.5em;
  }
  footer > ul > li a {
    color: var(--color-dark);
    text-decoration: none;
    transition: color 0.2s ease;
  }
  footer > ul > li i {
    font-size: 1.2em;
    margin-right: 0.2em;
  }
  footer > ul > li a:hover {
    color: rgba(88, 88, 88, 0.792);
  }
  footer > ul > li:first-child {
    font-size: 1.2rem;
    opacity: 0.7;
    margin-bottom: 0.5em;
    padding: 0;
  }
  footer > .copyright {
    grid-column: 2/3;
    grid-row: 3/4;
    font-size: 0.7rem;
    opacity: 0.5;
  }
  .route-enter-from {
    opacity: 0;
    transform: translateX(100px);
  }

  .route-enter-active {
    transition: 0.3s all ease-out;
  }

  .route-leave-to {
    opacity: 0;
    transform: translateX(-100px);
  }

  @media only screen and (max-width: 768px) {
    .logo {
      font-size: 1.5rem;
    }
    .ham {
      display: flex;
      grid-column: 3 / 4;
      justify-self: flex-end;
    }
    nav {
      position: fixed;
      height: 100%;
      flex-direction: column;
      right: 0;
      top: 5rem;
      width: 80%;
      background: var(--color-light);
      z-index: 900;
      padding-top: 3em;
      transform: translateX(100%);
      transition: transform 0.5s ease;
    }
    nav.active {
      transform: translateX(0);
    }
    nav > a {
      margin: 1em 0;
    }
    footer {
      display: flex;
      flex-direction: column;
      padding: 2rem;
    }
    footer > ul > li {
      font-size: 0.9rem;
    }
  }
</style>
