import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/contact.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import(/* webpackChunkName: "FAQ" */ "../views/FAQ.vue"),
  },
  {
    path: "/news",
    name: "NEWS",
    component: () => import(/* webpackChunkName: "news" */ "../views/news.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/services.vue"),
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () => import(/* webpackChunkName: "book" */ "../views/book.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  let height = 0;
  if (window.innerWidth > 1025) {
    height = 750;
  }
  else if (window.innerWidth > 426) {
    height = 1200;
  }
  else{
    height = 1600;
  }
  if (to.hash == "#stock") {
    return { top: height };
  }
  return savedPosition || { top: 0, left: 0 };
};

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

export default router;
